<template>
  <div class="error">404</div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style>
.error {
  line-height: 100px;
}
</style>
